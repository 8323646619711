import classNames from "classnames";
import { ButtonHTMLAttributes, PropsWithChildren } from "react";
import { Link, LinkProps } from "react-router-dom";
import styles from "./Clickable.module.scss";

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  to?: never;
};

type GenericProps = {
  className?: string;
  testId?: string;
  disabled?: boolean;
};

type AnchorProps = LinkProps & Required<Pick<LinkProps, "to">>;

export type ClickableProps = GenericProps & (AnchorProps | ButtonProps);

function isLinkProps(props: ButtonProps | LinkProps): props is LinkProps {
  return (props as LinkProps).to !== undefined;
}

export function Clickable({ children, testId, className, ...props }: PropsWithChildren<ClickableProps>) {
  const commonProps = {
    "data-testid": testId,
  };

  if (isLinkProps(props)) {
    const anchorProps = props;

    return (
      <Link {...commonProps} {...anchorProps} className={classNames(styles.anchorReset, className)}>
        {children}
      </Link>
    );
  }

  props = props as ButtonProps;

  const buttonProps = props;

  return (
    <button {...commonProps} {...buttonProps} className={classNames(styles.buttonReset, className)}>
      {children}
    </button>
  );
}
