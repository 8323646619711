import { Filter } from "@cubejs-client/core";
import { useCubeQuery } from "@cubejs-client/react";

type CubeQuery = {
  cube: string;
  dimensions: string[];
  filters?: Filter[];
  order?: { [key: string]: "asc" | "desc" };
};

export const useDataset = ({ cube, dimensions, filters, order }: CubeQuery) =>
  useCubeQuery({
    dimensions: dimensions.map((dimension) => `${cube}.${dimension}`),
    filters,
    order,
  });
