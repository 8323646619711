import { useGetIntercomSettingsQuery } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { Button } from "@simplicate/ui";
import { Module, asModule } from "@simplicate/utils";
import classnames from "classnames";
import { useIntercom } from "react-use-intercom";
import styles from "./HelpDropdown.module.scss";

const PRODUCT_PORTAL_URL = "//portal.productboard.com/vmj5ra15wh8kawwjx8ezetsn";
const SUPPORT_PAGE_BASE_URL = "//support.simplicate.nl/";
const SUPPORT_URLS: Record<Module, string> = {
  "business-intelligence": SUPPORT_PAGE_BASE_URL + "nl/collections/2573733-business-intelligence-bi",
  crm: SUPPORT_PAGE_BASE_URL + "crm",
  dashboard: SUPPORT_PAGE_BASE_URL,
  "environment-management": SUPPORT_PAGE_BASE_URL,
  hours: SUPPORT_PAGE_BASE_URL + "uren",
  hrm: SUPPORT_PAGE_BASE_URL + "hrm",
  invoicing: SUPPORT_PAGE_BASE_URL + "facturen",
  projects: SUPPORT_PAGE_BASE_URL + "projecten",
  "resource-planner": SUPPORT_PAGE_BASE_URL,
  sales: SUPPORT_PAGE_BASE_URL + "sales",
};

type HelpDropdownProps = {
  module?: string;
};

export const HelpDropdown = ({ module }: HelpDropdownProps) => {
  const { t } = useTranslation("top_bar");
  const { show } = useIntercom();
  const { data: intercomSettings } = useGetIntercomSettingsQuery(undefined, { refetchOnMountOrArgChange: true });
  const selectedModule = asModule(module) ?? "dashboard";

  const translatedModuleThemes: Record<Module, string> = {
    "business-intelligence": t("module_business_intelligence"),
    crm: t("module_crm"),
    dashboard: t("module_dashboard"),
    "environment-management": t("module_environment_management"),
    hours: t("module_hours"),
    hrm: t("module_hrm"),
    invoicing: t("module_invoicing"),
    projects: t("module_projects"),
    "resource-planner": t("module_resource_planner"),
    sales: t("module_sales"),
  };

  return (
    <div>
      <div className={classnames(styles.container, styles.divider)}>
        <div className={styles.innerContainer}>
          <p className={styles.text}>{t("help_text_1", { module: translatedModuleThemes[selectedModule] })}</p>
          <Button
            variant="primary"
            size="medium"
            target="_blank"
            to={SUPPORT_URLS[selectedModule]}
            text={t("help_button_support")}
          />
        </div>
        <div className={styles.innerContainer}>
          <p className={styles.text}>{t("help_text_2")}</p>
          <Button
            variant="secondary"
            size="medium"
            disabled={!intercomSettings?.is_enabled}
            onClick={show}
            text={t("help_button_question")}
            testId="help-question-button"
          />
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles.innerContainer}>
          <p className={styles.text}>{t("help_text_3")}</p>
          <Button
            variant="primary"
            size="medium"
            target="_blank"
            to={PRODUCT_PORTAL_URL}
            text={t("help_button_portal")}
          />
        </div>
      </div>
    </div>
  );
};
