import { Filter } from "@cubejs-client/core";

export type DashboardState = {
  filters: Filter[];
};

type AddFilterAction = {
  type: "ADD_FILTER";
  filter: Filter;
};

export type ReducerAction = AddFilterAction;

export function dashboardStateReducer(state: DashboardState, action: ReducerAction): DashboardState {
  switch (action.type) {
    case "ADD_FILTER":
      return {
        ...state,
        filters: [...state.filters, action.filter],
      };
  }
}
