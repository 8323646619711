import { simplicateApiV2, createTagTypes } from "@simplicate/api-client";

type AuthToken = string & { __tag: "AuthToken" };

function asAuthToken(token: string): AuthToken {
  return token as AuthToken;
}

type CubeConfig = {
  readonly token: AuthToken;
  readonly endpoint: string;
};

const cubeDevTags = createTagTypes({
  tagPrefix: "CubeDev",
  tags: ["authToken"],
});

const endpoints = simplicateApiV2.enhanceEndpoints({ addTagTypes: Object.values(cubeDevTags) }).injectEndpoints({
  endpoints: (builder) => ({
    getCubeConfig: builder.query<CubeConfig, void>({
      query: () => `cubeDev/jwt`,
      transformResponse: (response: { data: { jwt: string; endpoint: string } }) => ({
        token: asAuthToken(response.data.jwt),
        endpoint: response.data.endpoint,
      }),
      providesTags: [cubeDevTags.authToken],
    }),
  }),
});

export const { useGetCubeConfigQuery } = endpoints;
export const useCubeConfigPrefetch = () => endpoints.usePrefetch("getCubeConfig");
export const invalidateCubeConfigCache = () => endpoints.util.invalidateTags([cubeDevTags.authToken]);
