import {
  PropsWithChildren,
  SyntheticEvent,
  useRef,
  forwardRef,
  useImperativeHandle,
  KeyboardEvent,
  useState,
} from "react";
import styles from "./Dialog.module.scss";

type DialogProps = PropsWithChildren & {
  testId?: string;
};

export type DialogResult = { status: "cancel"; data?: never } | { status: "submit"; data?: FormData };

/* istanbul ignore next */
export const useDialogRef = () => useRef<DialogHandle>(null);

export interface DialogHandle {
  open: () => Promise<DialogResult>;
}

export const Dialog = forwardRef(function Dialog({ children, testId }: DialogProps, ref) {
  const dialogRef = useRef<HTMLDialogElement | null>(null);

  const [promise, setPromise] = useState<{
    resolve: (value: DialogResult | PromiseLike<DialogResult>) => void;
    reject: (reason?: null) => void;
  } | null>(null);

  useImperativeHandle(ref, () => ({
    open: () => {
      dialogRef.current?.showModal();

      return new Promise<DialogResult>((resolve, reject) => {
        setPromise({ resolve, reject });
      });
    },
  }));

  const handleKeyUp = (event: KeyboardEvent<HTMLElement>) => {
    if (event.key === "Escape") handleCancel();
  };

  const handleCancel = () => {
    dialogRef.current?.close();

    promise?.resolve({ status: "cancel" });
  };

  const handleSubmit = (event: SyntheticEvent<HTMLDialogElement, Event>) => {
    event.preventDefault();

    const formData = new FormData(event.target as HTMLFormElement);

    dialogRef.current ? dialogRef.current.close() : /* istanbul ignore next */ undefined;

    promise?.resolve({ data: formData, status: "submit" });
  };

  const handleClick = (event: SyntheticEvent<HTMLDialogElement, Event>) => {
    /* istanbul ignore next */
    if (event.target === event.currentTarget) handleCancel();
  };

  return (
    /* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions -- Dialog handles the key events */
    <dialog
      className={styles.dialog}
      data-testid={testId}
      ref={dialogRef}
      onKeyUp={handleKeyUp}
      onCancel={handleCancel}
      onReset={handleCancel}
      onSubmit={handleSubmit}
      onClick={handleClick}
    >
      {children}
    </dialog>
  );
});
