import { colorCommonGrey0, colorCommonGrey800 } from "@simplicate-software/design-tokens";
import classnames from "classnames";
import { getContrastColor } from "../../utils/getContrastColor";
import { Clickable, ClickableProps } from "../Clickable";
import { Icon, IconName } from "../Icon";
import styles from "./Tag.module.scss";

type TagProps = ClickableProps & {
  text: string;
  variant?: "color" | "dark" | "default" | "light";
  color?: string;
  size?: "medium" | "small";
  onClose?: () => void;
  icon?: IconName;
  testId?: string;
  href?: string;
  isElevated?: boolean;
  onClick?: () => void;
};

const variantMap = {
  default: colorCommonGrey800,
  color: colorCommonGrey0,
  light: colorCommonGrey800,
  dark: colorCommonGrey0,
} as const;

const InnerTag = ({ text, icon }: { text: string; icon?: IconName }) => (
  <>
    {icon && (
      <span className={styles.icon}>
        <Icon icon={icon} />
      </span>
    )}
    <span>{text}</span>
  </>
);

export const Tag = ({
  text,
  isElevated = false,
  size = "medium",
  variant = "default",
  icon,
  onClose,
  testId,
  color,
  href,
  onClick,
}: TagProps) => {
  const contrastColor = color ? getContrastColor(color) : variantMap[variant as keyof typeof variantMap];
  const tagStyle = variant === "color" ? { "--background-color": color, color: contrastColor } : undefined;

  return (
    <div
      style={tagStyle}
      className={classnames(styles.tag, isElevated && styles.elevated, styles[variant], styles[size])}
    >
      {href || onClick ? (
        <Clickable onClick={onClick} data-testid={testId} className={styles.tagInner} to={href}>
          <InnerTag text={text} icon={icon} />
        </Clickable>
      ) : (
        <div data-testid={testId} className={styles.tagInner}>
          <InnerTag text={text} icon={icon} />
        </div>
      )}

      {onClose && (
        <Clickable onClick={onClose} data-testid="tag-close-button" className={classnames(styles.icon, styles.close)}>
          <Icon icon="times" />
        </Clickable>
      )}
    </div>
  );
};
