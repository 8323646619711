// istanbul ignore file -- This is still a demo component
import { ColumnsTemplate, Page } from "@simplicate/ui";
import { CubeProvider } from "../CubeProvider";
import { Dashboard } from "../Dashboard";
import { DimensionValueSelect } from "../DimensionValueSelect/DimensionValueSelect";
import { Poc1Table } from "./dashboard/Poc1Table";

// TODO add testcases before leaving POC state
export const CubeDevPage = () => {
  return (
    <Page>
      <CubeProvider>
        <Dashboard>
          <ColumnsTemplate>
            <ColumnsTemplate.Column columnSpan={2}>
              <DimensionValueSelect
                cube="cube_employees"
                dimensions={{ label: "dim_employee_name", value: "dim_employee_id" }}
              />
            </ColumnsTemplate.Column>
          </ColumnsTemplate>
          <Poc1Table
            cube="cube_employees"
            columns={[
              { header: "Name", dimension: "dim_employee_name" },
              { header: "ID", dimension: "dim_employee_id" },
            ]}
          />
        </Dashboard>
      </CubeProvider>
    </Page>
  );
};
