import classnames from "classnames";
import { InputHTMLAttributes, useId } from "react";
import styles from "./Input.module.scss";

export type InputProps = Omit<InputHTMLAttributes<HTMLInputElement>, "id"> & {
  leftComponent?: React.ReactNode;
  rightComponent?: React.ReactNode;
  hasError?: boolean;
  label?: string;
  type: string;
  disabled?: boolean;
  name?: string;
  testId?: string;
  variant?: "compact" | "default";
};

export const Input = ({
  leftComponent,
  rightComponent,
  hasError,
  testId,
  label,
  disabled,
  name,
  value = "",
  variant = "default",
  ...inputProps
}: InputProps) => {
  const id = useId();

  return (
    <div
      data-testid={testId}
      className={classnames(styles.wrapper, disabled && styles.disabled, variant === "compact" && styles.compact)}
    >
      {label && (
        <label htmlFor={id} className={classnames(styles.label)}>
          {label}
        </label>
      )}
      <div data-testid={`inputWrapper-${testId}`} className={classnames(styles.inputWrapper, hasError && styles.error)}>
        {leftComponent && (
          <div className={styles.leftComponent} data-testid={`leftComponent-${testId}`}>
            {leftComponent}
          </div>
        )}
        <input
          data-testid={testId && `input-${testId}`}
          className={styles.input}
          disabled={disabled}
          name={name}
          id={id}
          value={value}
          {...inputProps}
        />
        {rightComponent && (
          <div className={styles.rightComponent} data-testid={`rightComponent-${testId}`}>
            {rightComponent}
          </div>
        )}
      </div>
    </div>
  );
};
