import { PropsWithChildren, useReducer } from "react";
import { DashboardContext } from "./Dashboard.context";
import { dashboardStateReducer } from "./Dashboard.reducer";

export const Dashboard = ({ children }: PropsWithChildren) => {
  const [state, dispatch] = useReducer(dashboardStateReducer, { filters: [] });

  return (
    <DashboardContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};
