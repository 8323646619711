// istanbul ignore file -- This is still a demo component
import { useDataset } from "../../data";
import { useDashboardContext } from "../Dashboard";

type ValueType = { label: string; value: string; key: string };

export const DimensionValueSelect = ({
  cube,
  dimensions: { label: labelDimension, value: valueDimension },
}: {
  cube: string;
  dimensions: { label: string; value: string };
}) => {
  const {
    actions: { addFilter },
  } = useDashboardContext();
  const { resultSet, isLoading, error } = useDataset({ cube, dimensions: [labelDimension, valueDimension] });

  if (error) {
    return <div>{error.message}</div>;
  }
  if (isLoading || !resultSet) {
    return null;
  }

  const labelField = cube + "." + labelDimension;
  const valueField = cube + "." + valueDimension;

  const options = resultSet
    .rawData()
    .map((row, idx) => ({ label: row[labelField], value: row[valueField], key: idx.toString(8) }))
    .filter((row): row is ValueType => !!row.label && !!row.value);

  const onChange = (value: string) => {
    addFilter({
      member: `${cube}.${valueDimension}`,
      operator: "equals",
      values: [value],
    });
  };

  return (
    <select onChange={(event) => onChange(event.target.value)}>
      {options.map(({ key, label, value: recordValue }) => (
        <option key={key} label={label} value={recordValue} />
      ))}
    </select>
  );
};
