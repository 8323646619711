import { Filter } from "@cubejs-client/core";
import { createContext, useContext, useMemo } from "react";
import { DashboardState, ReducerAction } from "./Dashboard.reducer";

type DashboardContextType = {
  state: DashboardState;
  dispatch: (action: ReducerAction) => void;
};

export const DashboardContext = createContext<DashboardContextType | null>(null);

export const useDashboardContext = () => {
  const context = useContext(DashboardContext);

  if (context === null) {
    throw new Error("Can only use `useDashboardContext` inside `DashboardContextProvider`");
  }

  const { state, dispatch } = context;

  const actions = useMemo(
    () => ({
      addFilter: (filter: Filter) => dispatch({ type: "ADD_FILTER", filter }),
    }),
    [dispatch],
  );

  return { state, actions } as const;
};
